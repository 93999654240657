import { Grid, TextField, Typography } from '@material-ui/core'
import { getApprovalValues } from 'ducks/selectors'
import useStyles from '../../styles'
import { useSelector } from 'react-redux'
import { useStepperContext } from 'hooks/useStepperContext'

const ApproximateHomeEquity = () => {
  const classes = useStyles()
  const { saveNewValue } = useStepperContext()

  const { approximateHomeEquity } = useSelector(getApprovalValues())
  return (
    <Grid item xs={12} lg={6} spacing={2}>
      <Typography className={classes.latoPrimaryText}>
        What is the approximate home equity
      </Typography>
      <TextField
        value={approximateHomeEquity?.toString() || ''}
        className={classes.textField}
        onChange={(e) => {
          saveNewValue('approximateHomeEquity', e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === '-' || (e.key === '0' && !approximateHomeEquity)) {
            e.preventDefault()
          }
        }}
        placeholder="Enter Home Equity (e.g.: $500,000 , 20% , etc.)"
      />
    </Grid>
  )
}

export default ApproximateHomeEquity
